import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Customer } from '../models/customer';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  fullApiUrl = environment.fullApiUrl;
  public currentCustomerSubject: BehaviorSubject<
    Customer
  > = new BehaviorSubject<Customer>(new Customer());

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  public getOneCustomer(idCustomer: number): Observable<Customer> {
    return this.http.get<Customer>(this.fullApiUrl + '/customers/' + idCustomer);
  }

  public getCurrentCustomer(): Observable<Customer> {
    this.getOneCustomer(
      this.authenticationService.tokenPayLoadSubject.value.customer
    ).subscribe(customer => {
      this.currentCustomerSubject.next(customer);
    });
    return this.currentCustomerSubject.asObservable();
  }

  // ..sts/customers/{customerId}
  public putCustomer(customer: Customer): Observable<Customer> {
    console.log(customer)
    return this.http.put<Customer>(
      this.fullApiUrl +
        '/customers/' +
        this.authenticationService.tokenPayLoadSubject.value.customer,
      customer
    );
  }
}
