import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cnaeFormat'
  })
  export class CnaeFormatPipe  implements PipeTransform {
  transform(cnae: string): string {
    let newCnae: string = "";
    newCnae = cnae.slice(13,cnae.length);
    return newCnae;
  }

  }
