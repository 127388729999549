import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'razaoSocial'
})
export class RazaoSocialPipe implements PipeTransform {
    transform(razaoSocial: string,  key: string): string {
        if (razaoSocial.length>32){
            let razaoSocialNew = razaoSocial.slice(0, 28).concat('...');
            return razaoSocialNew;
        }
        return razaoSocial;
    }
}
