import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/auth/authentication.service';
import { map } from 'rxjs/operators';
import { ResponsePageable } from '../models/responsePageable.model';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  apiUrl = environment.fullApiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  idCustomer;

  constructor(private http: HttpClient, private auth: AuthenticationService) {
    this.auth.tokenPayLoadSubject
      .asObservable()
      .subscribe((x) => (this.idCustomer = x.customer));
  }

  downloadDocument(documentName: string): Observable<any> {
    return this.http
      .get(this.apiUrl + '/document/download?documentName=' + documentName, {
        responseType: 'blob',
      })
      .pipe(
        map((response) => {
          return {
            filename: documentName,
            data: response,
          };
        })
      );
  }

  getGeneralCertificates(
    pageIndex: number,
    pageSize: number
  ): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.apiUrl +
        '/general-certificates?page=' +
        pageIndex +
        '&size=' +
        pageSize +
        '&sort=desc'
    );
  }

  // https://api-qa.uqualifyapp.com/sts/general-certificates?page=0&size=100&sort=desc
}
