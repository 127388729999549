import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'qsa'
})
export class QsaPipe implements PipeTransform {
    transform(qsaString: string,  key: string): string {
        if (qsaString){
            let qsaJson = qsaString;
            if(key){return qsaJson[key] || '************'};
            return qsaJson;
        }
        return qsaString;
    }
}
