import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
  })
  export class DateFormatPipe  implements PipeTransform {
  transform(date: string): string {
    let newDate: string = "";
    newDate = date.slice(0,10);
    return newDate;
  }

  }
