
export class TokenPayLoad {

    sub: string;
    // roles: string[];
    customer: number;
    iat: number;
    ex: number;
    preferred_username: string;
}
