import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';

export const authConfig: AuthConfig = {

  issuer: environment.keycloack.issuer,
  redirectUri: window.location.origin,
  clientId: 'uqualify-web',
  responseType: 'code',
  disableAtHashCheck: true,
  requireHttps: false,
  showDebugInformation: true,
  scope: 'openid profile email offline_access'
}
