import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/auth/authentication.service';
import { ResponsePageable } from '../models/responsePageable.model';
import { DocumentResult } from '../models/documentResult.model';

@Injectable({ providedIn: 'root' })
export class ResultsService {
  //   fullApiUrl = environment.fullApiUrl;
  partialApiUrl = environment.partialApiUrl;
  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService
  ) {}

  public getAllOrderResults(idOrder: string): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.partialApiUrl + '/orders/' + idOrder + '/results?size=100'
    );
  }

  public getResults(
    documentName: string,
    cnpj: string,
    size: number
  ): Observable<ResponsePageable> {
    if (documentName != null && size != 0) {
      return this.http.get<ResponsePageable>(
        this.partialApiUrl +
          '/results?nomeDocumento=' +
          documentName +
          '&cnpj=' +
          cnpj +
          '&size=' +
          size
      );
    } else {
      return this.http.get<ResponsePageable>(
        this.partialApiUrl + '/results?cnpj=' + cnpj
      );
    }
  }

  public getResultsByDocumentName(
    documentName: string,
    pageIndex: number,
    pageSize: number
  ): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.partialApiUrl +
        '/results?nomeDocumento=' +
        documentName +
        '&page=' +
        pageIndex +
        '&size=' +
        pageSize +
        '&sort=desc'
    );
  }

  public getResultById(idResult: string): Observable<DocumentResult> {
    return this.http.get<DocumentResult>(
      this.partialApiUrl + '/results/' + idResult
    );
  }

  public getResultsByDocumentNameAndStatus(
    documentName: string,
    statusHomologacao: string,
    pageIndex: number,
    pageSize: number
  ): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.partialApiUrl +
        '/results?nomeDocumento=' +
        documentName +
        '&statusHomologacao=' +
        statusHomologacao +
        '&page=' +
        pageIndex +
        '&size=' +
        pageSize +
        '&sort=desc'
    );
  }
}
