import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'localDateTimePipe'
})
export class LocalDateTimePipe implements PipeTransform {
    transform(date: string): string {
        let dateOut: moment.Moment = moment.utc(date, "DD-MM-YYYY HH:mm:ss").local();
        return dateOut.format("DD-MM-YYYY HH:mm");
    }

}
