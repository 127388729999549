import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../services/auth/authentication.service';
import { environment } from 'environments/environment';
import { User } from '../models/user';
import { ResponsePageable } from '../models/responsePageable.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageEvent } from '@angular/material';

@Injectable({ providedIn: 'root' })
export class UserService {
  private x: any;
  fullApiUrl = environment.fullApiUrl;
  partialApiUrl = environment.partialApiUrl;
  public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(
    new User()
  );

  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService,
  ) {
    this.authentication.tokenPayLoadSubject
    .asObservable()
    .subscribe((x) => (this.x = x));
  }

  public getCustomerDetails() {
    return this.http.get<any>( this.partialApiUrl + '/users/roles/' + this.x.sub );
  }

  public getUserDetails(userId: string) {
    return this.http.get<any>( this.partialApiUrl + '/users/roles/' + userId );
  }

  public getUsers(customerId: string) {
    return this.http.get<any>( this.fullApiUrl + '/customers' + customerId + '/users?page=0');
  }

  public addRoles(userId: string) {
    return this.http.post<any>( this.partialApiUrl +'/users/roles/' + userId + '?role=ADMIN', null );
  }

  public deleteRoles(userId: string) {
    return this.http.delete<User>( this.partialApiUrl +'/users/roles/' + userId + '?role=ADMIN');
  }

  // GET sts/customers/users/{idUser}
  public getOneCustomerUser(idUser: number) {
    return this.http.get<User>(
      this.fullApiUrl +
        '/customers/' +
        this.authentication.tokenPayLoadSubject.value.customer +
        '/users/' +
        idUser
    );
  }

  // POST sts/customers/users
  public postCustomerUser(user: User): Observable<User> {
    return this.http.post<User>(
      this.fullApiUrl +
        '/customers/' +
        this.authentication.tokenPayLoadSubject.value.customer +
        '/users',
      user
    );
  }

  // POST sts/customers/users
  public postCustomerUserWithCustomerId(
    user: User,
    customerId: string
  ): Observable<User> {
    return this.http.post<User>(
      this.fullApiUrl + '/customers/' + customerId + '/users',
      user
    );
  }

  // POST /sts/users/27353/roles
  public postUserRoleMaster(idUser: number, role: string): Observable<User> {
    return this.http.post<User>(
      this.fullApiUrl + '/users/' + idUser + '/roles',
      { roleName: role }
    );
  }

  public postCustomerUserOauthWithCustomerId(
    user: User,
    customerId: string
  ): Observable<User> {
    return this.http.post<User>(
      this.fullApiUrl + '/user/oauth/' + customerId,
      user
    );
  }

  // DELETE sts/customers/users/{idUser}
  public deleteCustomerUser(user: User): Observable<User> {
    return this.http.delete<User>(
      this.fullApiUrl +
        '/customers/' +
        this.authentication.tokenPayLoadSubject.value.customer +
        '/users/' +
        user.id
    );
  }

  // PUT sts/customers/users/{idUser}
  public putCustomerUser(user: User): Observable<any> {
    return this.http.put(
      this.fullApiUrl +
        '/customers/' +
        this.authentication.tokenPayLoadSubject.value.customer +
        '/users/' +
        user.id,
      user
    );
  }

  // GET sts/customers/users?username=sub
  public getCurrentUser(): Observable<User> {
    this.http
      .get<User>(
        this.fullApiUrl +
          '/customers/' +
          this.authentication.tokenPayLoadSubject.value.customer +
          '/userPrincipal',
        {
          params: new HttpParams().set(
            'username',
            this.authentication.tokenPayLoadSubject.value.sub
          ),
        }
      )
      .subscribe((data: User) => {
        this.currentUserSubject.next(data);
      });
    return this.currentUserSubject.asObservable();
  }

  // GET sts/customers/users?username= customer
  public checkUsernamExists(username: string): Observable<Boolean> {
    const checked: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(
      false
    );
    this.http
      .get<ResponsePageable>(this.fullApiUrl + '/users/', {
        params: new HttpParams().set('username', username),
      })
      .subscribe((result: ResponsePageable) => {
        if (result.totalElements === 0) {
          checked.next(false);
        } else {
          checked.next(true);
        }
      });
    return checked.asObservable();
  }

  // GET sts/customers/users
  public getAllCustomerUsers(
    pageEvent: PageEvent
  ): Observable<ResponsePageable> {
    let params = new HttpParams();
    params = params.append(
      'page',
      pageEvent.pageIndex ? pageEvent.pageIndex.toString() : '0'
    );
    params = params.append(
      'size',
      pageEvent.pageSize ? pageEvent.pageSize.toString() : '0'
    );

    return this.http.get<ResponsePageable>(
      this.fullApiUrl +
        '/customers/' +
        this.authentication.tokenPayLoadSubject.value.customer +
        '/users',
      { params: params }
    );
  }

  // GET /userLog
    public getUsersLog(pageIndex:number, pageSize: number){
      return this.http.get<ResponsePageable>(
        this.partialApiUrl + '/userLog?page=' + pageIndex + '&size=' + pageSize
      )
    }
}
