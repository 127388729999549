import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemplateRule } from 'app/shared/models/templateRule';
import { Category } from 'app/shared/models/category';
import { Document } from 'app/shared/models/document';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Paises } from 'app/shared/models/paises.model';
import { TemplateRulesService } from 'app/shared/api/templates-rules.service';

@Component({
  selector: 'app-template-rule-dialog',
  templateUrl: './template-rule-dialog.component.html',
  styleUrls: ['./template-rule-dialog.component.scss'],
})
export class TemplateRuleDialogComponent implements OnInit {
  public templateForm: FormGroup;
  public template: TemplateRule;
  public visualizar: boolean = false;
  public paises: any;


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TemplateRuleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public templateRuleParam: TemplateRule,
    private templateRuleService: TemplateRulesService,
  ) {
    
  }

  ngOnInit() {
    if (this.templateRuleParam) {
      this.visualizar = true;
      this.buildItemForm(this.templateRuleParam);
      this.getPaises();
    }
  }

  fechar(): void {
    this.dialogRef.close(null);
  }

  buildItemForm(template: TemplateRule) {

    var tipo = template.entidade ? 'entidade' : 'individuo';


    this.templateForm = this.fb.group({
      id: [template.id || null],
      nomeTemplate: [template.nomeTemplate || '', Validators.required],
      versaoTemplate: [template.versaoTemplate || '', Validators.required],
      notaCorte: [template.notaCorte || 60, Validators.required],
      descricaoTemplate: [
        template.descricaoTemplate || '',
        Validators.required,
      ],
      pais: [template.pais.id || '',  Validators.required],
      tipo: [tipo || '',  Validators.required],
      ativo: [template.ativo || true, Validators.required],
      categorias: this.fb.array(this.initCategories(template.categorias)),
      emailNotificacao: [
        template.emailNotificacao || 'Não Configurado',
        Validators.required,
      ],
      periodoRequalificacao: [
        template.periodoRequalificacao || 0,
        Validators.required,
      ],
    });
  }

  getPaises(): void {
    this.templateRuleService.getCountries().subscribe((data) => {
      this.paises = data;
    });
  }

  initCategories(categoryList: Category[]) {
    let categoryArray: any[] = [];
    for (let index = 0; index < categoryList.length; index++) {
      categoryArray.push(
        this.fb.group({
          nomeCategoria: [
            categoryList[index].nomeCategoria || '',
            Validators.required,
          ],
          pesoCategoria: [
            {
              value: categoryList[index].pesoCategoria || 0,
              disabled: this.visualizar,
            },
            Validators.required,
          ],
          eliminatorio: [
            {
              value: categoryList[index].eliminatorio || 0,
              disabled: this.visualizar,
            },
          ],
          documentos: this.fb.array(
            this.initDocuments(categoryList[index].documentos)
          ),
        })
      );
    }
    return categoryArray;
  }

  initDocuments(documentList: Document[]) {
    let documentArray: any[] = [];
    for (let index = 0; index < documentList.length; index++) {
      documentArray.push(
        this.fb.group({
          nomeDocumento: [
            documentList[index].nomeDocumento || '',
            Validators.required,
          ],
          pesoDocumento: [
            {
              value: documentList[index].pesoDocumento || 0,
              disabled: this.visualizar,
            },
            Validators.required,
          ],
          eliminatorio: [
            {
              value: documentList[index].eliminatorio || false,
              disabled: this.visualizar,
            },
          ],
        })
      );
    }
    return documentArray;
  }

  get f() {
    return this.templateForm.controls;
  }

  onInputChange(event: any) {}
}
