import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-layout',
  templateUrl: './edit-layout.component.html'
})
export class EditLayoutComponent implements OnInit {

  public  scrollConfig = {}

  constructor() { }

  ngOnInit() {
  }

}
