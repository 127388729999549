import { Component, Input, OnInit } from '@angular/core';
import { Supplier } from 'app/shared/models/supplier.model';
import { Order } from 'app/shared/models/order.model';
import { SupplierService } from 'app/shared/api/supplier.service';
import { ResponsePageable } from 'app/shared/models/responsePageable.model';
import { format } from 'date-fns';

@Component({
  selector: 'app-supplier-datas-international',
  templateUrl: './supplier-datas-international.component.html',
  styleUrls: ['./supplier-datas-international.component.css']
})
export class SupplierDatasInternationalComponent implements OnInit {
  @Input() order: any;
  @Input() supplier: any;
  @Input() topActions: boolean;
  public fornecedorModel: any;
  public categoryModel: any;
  public dataRegistro: string;

  constructor(
    private supplierService: SupplierService
  ) { }

  ngOnInit() {
    if (this.supplier) {
      this.fornecedorModel = this.supplier.fornecedorModel;
      this.categoryModel = this.supplier.categoryModel;
    }else if (this.order) {
      this.getSupplierDatas(this.order.taxId);
    }else{
      console.log("ERRO SUPPLIER DATA INTERNATIONAL");
    }
  }

  getSupplierDatas(taxId: string){
    this.supplierService.getOneSupplierByTaxId(taxId).subscribe((data: ResponsePageable) => {
        this.supplierService.getOneSupplierById(data.content[0].id).subscribe((supplier: ResponsePageable)=>{
          this.supplier = supplier.content[0];
          this.fornecedorModel = supplier.content[0].fornecedorModel;
          this.categoryModel = supplier.content[0].categoryModel;
        })
      })
  }
}
