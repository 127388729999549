import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Requalification } from 'app/shared/models/requalification.model';
import { RequalificationService } from 'app/shared/api/requalification.service';
import { ResponsePageable } from 'app/shared/models/responsePageable.model';

@Component({
  selector: 'app-requalification-list-dialog',
  templateUrl: './requalification-list-dialog.component.html',
  styleUrls: ['./requalification-list-dialog.component.scss']
})
export class RequalificationListDialogComponent implements OnInit {


  public requalificationList: Requalification[] = null;
  public cnpjSupplier: string;


  constructor(
    public dialogRef: MatDialogRef<RequalificationListDialogComponent>,
    public requalificationService: RequalificationService,
    @Inject(MAT_DIALOG_DATA) public cnpj: string
  ) {}

  ngOnInit() {
    this.cnpjSupplier = this.cnpj;
    this.getAllRequalificationCustomerBySupplier()

  }

  getAllRequalificationCustomerBySupplier(): void{
    this.requalificationService.getAllCustomerRequalification(this.cnpjSupplier).subscribe((data: ResponsePageable) => {
      this.requalificationList = data.content;
    });
  }


  cancelar(): void {
    this.dialogRef.close("Fechou");
  }


}
