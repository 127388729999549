import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'atividadeSecundaria'
})
export class AtividadeSecundariaPipe implements PipeTransform {
    transform(cnaeSecundarioString: string,  key: string): string {
        if (cnaeSecundarioString){
            let cnaeSecundarioJson = cnaeSecundarioString;
            if(key){return cnaeSecundarioJson[key] || '************'};
            return cnaeSecundarioJson;
        }
        return cnaeSecundarioString;
    }
}
