import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateTimeFormat'
  })
  export class DateTimeFormatPipe  implements PipeTransform {
  transform(date: string): string {
    let newDate: string = "";
    newDate = date.slice(0,16);
    return newDate;
  }

  }
