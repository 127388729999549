import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateTimeFormatFirestore'
  })
  export class DateTimeFormatFirestorePipe  implements PipeTransform {
  transform(date: firebase.firestore.Timestamp): string {
      if(date){
        let newDate: moment.Moment = moment.utc(date.toDate()).local();
        return newDate.format('DD-MM-YYYY');
      }else{
        return '****';
      }
  }

  }
