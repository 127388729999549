import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { TokenPayLoad } from 'app/shared/models/tokenPayLoad';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  apiUrlAuth = environment.apiUrlAuth;
  utilToken = new JwtHelperService();

  private currentAccessCredential: BehaviorSubject<TokenResponse>;
  private tokenPayLoad: BehaviorSubject<TokenPayLoad> =
    new BehaviorSubject<TokenPayLoad>(new TokenPayLoad());
  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: OAuthService
  ) {
    try {
      this.currentAccessCredential = new BehaviorSubject<TokenResponse>(
        this.utilToken.decodeToken(localStorage.getItem('access_token'))
      );
      if (this.currentAccessCredential.value) {
        this.initAuthenticate();
      }
    } catch (e) {
      this.logout();
    }
  }

  private initAuthenticate() {
    this.tokenPayLoad.next(
      this.utilToken.decodeToken(localStorage.getItem('access_token'))
    );
    this.loggedIn.next(true);
  }

  public get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  public get currentAccessCredentialSubject() {
    return this.currentAccessCredential;
  }

  public get tokenPayLoadSubject() {
    return this.tokenPayLoad;
  }

  async loginProvider(token: TokenResponse) {
    if (token.access_token) {
      this.currentAccessCredential.next(token);
      this.tokenPayLoad.next(
        JSON.parse(
          JSON.stringify(this.utilToken.decodeToken(token.access_token))
        )
      );
      this.loggedIn.next(true);
    }
    return token;
  }

  login(username: string, password: string) {
    return this.authService
      .fetchTokenUsingPasswordFlow(username, password)
      .then((token) => {
        if (token.access_token) {
          this.currentAccessCredential.next(token);
          this.initAuthenticate();
          this.tokenPayLoad.next(
            JSON.parse(
              JSON.stringify(this.utilToken.decodeToken(token.access_token))
            )
          );
          this.loggedIn.next(true);
        }
        return token;
      });
  }

  resetPassword(
    sub: string,
    username: string,
    password: string,
    newPassword: string
  ): Observable<any> {
    return this.http.put<any>(this.apiUrlAuth + '/reset-password', {
      sub,
      username,
      password,
      newPassword,
    });
  }

  logout() {
    if (!window.location.href.includes('/sessions/signin')) {
      this.authService.logOut();
      this.loggedIn.next(false);
      localStorage.removeItem('accessCredential');
      // this.currentAccessCredential.next(new TokenRe());
      this.tokenPayLoad.next(new TokenPayLoad());
      window.location.replace('/sessions/signin');
    }
  }
}
