import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { QualificationLimitModel } from 'app/shared/models/qualificationLimit.model';

@Component({
  selector: 'alert-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  message: String;
  title: String;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {
    this.message = this.dialogData.message;
    this.title = this.dialogData.title;
  }

  close(): void {
    this.dialogRef.close(new QualificationLimitModel());
  }
}
