import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Observable } from 'rxjs';
import { ResponsePageable } from '../models/responsePageable.model';

@Injectable({ providedIn: 'root' })
export class ServiceStatusService {
  fullApiUrl = environment.fullApiUrl;
  partialApiUrl = environment.partialApiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  idCustomer;

  constructor(private http: HttpClient, private auth: AuthenticationService) {
    this.auth.tokenPayLoadSubject
      .asObservable()
      .subscribe((x) => (this.idCustomer = x.customer));
    // console.log(this.idCustomer);
  }

  getGeneralCertificates(
    pageIndex: number,
    pageSize: number
  ): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.fullApiUrl +
        '/general-certificates?page=' +
        pageIndex +
        '&size=' +
        pageSize +
        '&sort=desc'
    );
  }
}
