import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'atividadePrincipal'
})
export class AtividadePrincipalPipe implements PipeTransform {
    transform(cnaeString: string, key: string): string {
        // if (cnaeString) return cnaeString.slice(9,cnaeString.indexOf('"code"')-2);
        if (cnaeString){
            let cnaeJson = JSON.parse(cnaeString);
            if(key){return cnaeJson[key] || '************'}
            return cnaeJson;
        }
        return cnaeString || '************';
    }
    // "{"text":"Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação","code":"46.52-4-00"}"
}
