import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../services/auth/authentication.service';
import { Observable } from 'rxjs';
import { ResponsePageable } from '../models/responsePageable.model';
import { Template } from '../models/template.model';
import { TemplateRule } from '../models/templateRule';

@Injectable({ providedIn: 'root' })
export class TemplatesService {
  fullApiUrl = environment.fullApiUrl;
  partialApiUrl = environment.partialApiUrl;

  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService
  ) {}

  getAllCustomerTemplates(): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.fullApiUrl +
        '/customers/' +
        this.authentication.tokenPayLoadSubject.value.customer +
        '/templates?status=VALIDO&ativo=true&size=1000'
    );
  }

  getAllCustomerTemplatesDeleted(): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.fullApiUrl +
        '/customers/' +
        this.authentication.tokenPayLoadSubject.value.customer +
        '/templates?status=DELETADO&ativo=false&size=1000'
    );
  }

  getCustomerTemplates(): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.fullApiUrl +
        '/customers/' +
        this.authentication.tokenPayLoadSubject.value.customer +
        '/templates?size=1000'
    );
  }

  getTemplateById(idTemplate: number) {
    return this.http.get<Template>(
      this.partialApiUrl + '/templates/' + idTemplate
    );
  }

  getTemplates(): Observable<ResponsePageable> {
    return this.http.get<ResponsePageable>(
      this.partialApiUrl + '/templates?status=VALIDO&ativo=true&size=1000'
    );
  }

  inactivateTemplate(templateId: number): Observable<ResponsePageable> {
    return this.http.delete<ResponsePageable>(
      this.partialApiUrl + `/templates/${templateId}`
    );
  }

  activateTemplate(templateId: number) {
    return this.http.patch<any>(this.partialApiUrl + `/templates/${templateId}`, null);
  }
}
