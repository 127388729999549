import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateTimeFormatIso'
  })
  export class DateTimeFormatIsoPipe  implements PipeTransform {
  transform(date: string): string {
    let newDate: moment.Moment = moment.utc(date).local();
    let newDateStr = newDate.format('DD-MM-YYYY HH:mm');
    return newDateStr;
  }

  }
