import { Category } from './category';

export class TemplateRule {
  id: number;
  nomeTemplate: string;
  notaCorte: number;
  descricaoTemplate: string;
  pais: any;
  entidade: boolean;
  tipo: string;
  individuo: boolean;
  status: string;
  ativo: boolean;
  versaoTemplate: string;
  dataCriacaoTemplate?: string;
  dataInicialExecucao?: null;
  dataFinalExecucao?: null;
  empresa?: string;
  categorias: Category[];
  emailNotificacao: string;
  periodoRequalificacao: number;
  TemplateTOSave: { id: any; };
}
