import { Pipe, PipeTransform } from '@angular/core';
import { TemplateRule } from '../models/templateRule';

@Pipe({
    name: 'getTemplateNome',
    pure: true
  })
  export class GetTemplateNomeInOrderPipe implements PipeTransform {
    transform(value: string, args?: any): any {
      return this.getTemplateNome(value);
    }
    getTemplateNome(templateString: string): String{
        let templateRule: TemplateRule = JSON.parse(templateString);
        return templateRule.nomeTemplate || null;
    }
  }
