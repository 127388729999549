import { CategoryModel } from './categoryModel';
import { FornecedorModel } from './fornecedorModel';
import { Paises } from './paises.model';
import { SupplierCategoryModel } from './supllier-category-model';

export class Supplier {
  id: number;
  cnpj: string;
  razaoSocial: string;
  dataAbertura: string;
  porte: string;
  fantasia: string;
  atividadePrincipal: string;
  atividadesSecundarias: string;
  naturezaJuridica: string;
  cep: string;
  logradouro: string;
  numero: number;
  complemento: string;
  bairro: string;
  uf: string;
  enderecoEletronico: string;
  telefone: string;
  situacaoCadastral: string;
  dataSituacaoCadastral: string;
  motivoSituacaoCadastral: string;
  situacaoEspecial: string;
  dataSituacaoEspecial: string;
  invalido: boolean;
  dataConsulta: string;
  casdastroFinalizado: boolean;
  municipio: string;
  qsa: string;
  filiais: string;
  categories: SupplierCategoryModel[];
  capitalSocial: string;
  avatar: string;
  status;
  fornecedorClientId: number;
  pais: Paises;
  taxId: string;
  fornecedorModel: FornecedorModel;
  categoryModel: CategoryModel
  entidade: boolean;
  individuo: boolean;
}
