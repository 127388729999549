import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/auth/authentication.service';


@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        // location.reload(true);
      }

      if (err.status === 403) {
        console.log('403 = sem permissão para consumir o conteúdo solicitado');
      }

      // if (err.status === 404) {
      //    this.router.navigate(['/sessions/404']);
      // }

      // const error = err.error.message || err.statusText;
      // return throwError(error);
      return next.handle(request);
    }));
  }
}
