import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/auth/authentication.service';
import { ResponsePageable } from '../models/responsePageable.model';
import { RequalificationSchedule } from '../models/requalificationSchedule.model';

@Injectable({ providedIn: 'root' })
export class RequalificationService {

//   fullApiUrl = environment.fullApiUrl;
  partialApiUrl = environment.partialApiUrl;

  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService
  ) {}

  // public getAllCustomerRequalification(cnpj: string): Observable<ResponsePageable> {
  //   return this.http.get<ResponsePageable>(this.requalificationServiceUrl+ '/api/customers/'+ this.authentication.tokenPayLoadSubject.value.customer +'/requalifications?cnpj='+ cnpj);
  // }

  public getAllCustomerRequalification(cnpj: string): Observable<ResponsePageable> {
      return this.http.get<ResponsePageable>(this.partialApiUrl+ '/requalifications/customers/'+ this.authentication.tokenPayLoadSubject.value.customer +'/requalifications?cnpj='+ cnpj+'&sort=dataRequalificacao');
  }

  public postCustomerRequalificationSchedule(requalificationSchedule: RequalificationSchedule): Observable<RequalificationSchedule> {
    return this.http.post<RequalificationSchedule>(this.partialApiUrl+ '/requalifications/customers/'+ this.authentication.tokenPayLoadSubject.value.customer + '/requalifications', requalificationSchedule );
  };


}
