import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { RequalificationService } from 'app/shared/api/requalification.service';
import { TemplatesService } from 'app/shared/api/templates.service';
import { APP_DATE_FORMATS, AppDateAdapter } from 'app/shared/helpers/format-datepicker';
import { RequalificationSchedule } from 'app/shared/models/requalificationSchedule.model';
import { ResponsePageable } from 'app/shared/models/responsePageable.model';
import { Template } from 'app/shared/models/template.model';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { FileDataDialogComponent } from 'app/views/supplier/register-supplier/file-datas/file-data-dialog/file-data-dialog.component';
import * as moment from 'moment';


@Component({
  selector: 'app-requalification-schedule-dialog',
  templateUrl: './requalification-schedule-dialog.component.html',
  styleUrls: ['./requalification-schedule-dialog.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    // { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]

})
export class RequalificationScheduleDialogComponent implements OnInit {

  public requalificationScheduleForm: FormGroup;
  public templatesList: Template[] = [];

  public minDate = moment().add(1, 'days').toDate();

  constructor(
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private requalificationService: RequalificationService,
    private templateService: TemplatesService,
    private confirmService: AppConfirmService,
    public dialogRef: MatDialogRef<FileDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public cnpjData: string
  ) { }

  ngOnInit() {
    this.getAllTemplates();
    let requalificationScheduleNew = new RequalificationSchedule()
    requalificationScheduleNew.cnpj = this.cnpjData;
    this.buildForm(requalificationScheduleNew);
  }


  get f() {
    return this.requalificationScheduleForm.controls;
  }

  buildForm(requalificationSchedule: RequalificationSchedule) {
    // let dataSerializer: Date = requalificationSchedule.dataRequalificacao.toDate();
    this.requalificationScheduleForm = this.fb.group({
      id: [requalificationSchedule.id || null],
      cnpj: [requalificationSchedule.cnpj || '', Validators.required],
      templateId: [requalificationSchedule.templateId || null, Validators.required],
      dataRequalificacao: [ null, Validators.required]
    });
  }

  getAllTemplates(){
    this.templateService.getAllCustomerTemplates().subscribe((pageableTemplate: ResponsePageable) => {
      this.templatesList = pageableTemplate.content;
    });
  }

  salvar(): void {
    let msg:string = null;
    const requalificationScheduleFormResult: RequalificationSchedule = this.requalificationScheduleForm.value;
    requalificationScheduleFormResult.dataRequalificacao =  moment(requalificationScheduleFormResult.dataRequalificacao).format('DD-MM-YYYY');
    this.confirmService.confirm({ message: msg }).subscribe(res => {
        if (res) {
          this.requalificationService.postCustomerRequalificationSchedule(requalificationScheduleFormResult).subscribe((requalificationSchedule: RequalificationSchedule) => {
            this.dialogRef.close(requalificationSchedule);
            this.snack.open('Requalificação Automática Agendada com Sucesso!', 'OK', { duration: 6000 });
          }, err => {
            this.snack.open('Erro ao Criar Agendamento - Tente Novamente!', 'OK', { duration: 6000 });
          });
        }
      });
  }

  cancelar(): void {
    this.dialogRef.close(new RequalificationSchedule());
  }

}
