import { Paises } from './paises.model';

export class Customer {
  id: number;
  cliente_cnpj: string;
  nome: string;
  referencia: string;
  status: string;
  email: string;
  contato: string;
  logo: string;
  limiteQualificacaoConsulta: number;
  contadorQualificacaoConsulta: number;
  dataLimiteQualificacaoConsulta: any;
  porcentagemCompletadaLimite: number;
  limiteQualificacaoConsultaInternacional: number;
  contadorQualificacaoConsultaInternacional: number;
  porcentagemCompletadaLimiteInternacional: number;
  pais: Paises;
  idioma: string;
}
