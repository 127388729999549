export class User {
  id: number;
  username: string;
  password?: string;
  nome: string;
  email: string;
  dataCriacao: string;
  status: string;
  sso: boolean;
  ssoId: string;
  userType: string;
}
